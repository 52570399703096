import type { DeepPartial, User } from "@directus/types";

export interface BoardImageItem extends Record<string, any> {
  id?: string;
  boards_id?: BoardItem;
  images_id?: ImageItem;
  posts_id?: PostItem;
}

export interface BoardItem extends Record<string, any> {
  id?: string;
  title?: string;
  owner?: { id?: string };
  items?: BoardImageItem[];
}

export const useBoards = () => {
  const { getItems, getItemById, createItems, deleteItems, updateItem } =
    useDirectusItems();
  const { currentUser } = useAuth();

  const getBoard = async (id: MaybeRef<string>) => {
    return await getItemById<BoardItem>({
      collection: "boards",
      id: unref(id),
      params: {
        fields: [
          "id",
          "title",
          "items.images_id.*.*",
          "items.posts_id.*.*",
          "owner",
        ],
      },
    });
  };

  const getBoards = async () => {
    return currentUser.value
      ? await getItems<BoardItem>({
          collection: "boards",
          params: {
            filter: { owner: { id: { _eq: currentUser.value.id } } },
            sort: ["title"],
            fields: [
              "id",
              "title",
              "items.images_id.*.*",
              "items.posts_id.*.*",
            ],
          },
        })
      : [];
  };

  const getBoardImage = async (board: BoardItem, image: ImageItem) => {
    return await getItems<BoardImageItem>({
      collection: "boards_images",
      params: {
        filter: {
          boards_id: { _eq: board?.id },
          images_id: { _eq: image?.id },
        },
        fields: ["id", "boards_id", "images_id", "posts_id"],
      },
    });
  };

  const createBoard = async (board: MaybeRef<BoardItem>) => {
    return await createItems<BoardItem>({
      collection: "boards",
      items: [unref(board)],
    });
  };

  const hasBoardImage = async (board: BoardItem, image: ImageItem) => {
    return (await getBoardImage(board, image)).length > 0;
  };

  const addImageToBoard = async (
    board: BoardItem,
    image: ImageItem,
    post: PostItem
  ) => {
    return await createItems<BoardImageItem>({
      collection: "boards_images",
      items: [
        {
          boards_id: board.id,
          images_id: image.id,
          posts_id: post.id,
        },
      ],
    });
  };

  const removeImageFromBoard = async (board: BoardItem, image: ImageItem) => {
    const items = await getBoardImage(board, image);

    return await deleteItems({
      collection: "boards_images",
      items: items.reduce<string[]>((acc, item) => {
        if (item.id) acc.push(item.id);
        return acc;
      }, []),
    });
  };

  const isImageOnAnyUserBoard = async (imageId: MaybeRef<string>) => {
    const user = currentUser.value as User;
    if (!user) return false;
    const items = await getItems<BoardImageItem>({
      collection: "boards_images",
      params: {
        filter: {
          images_id: { _eq: imageId },
          boards_id: { owner: { id: user.id } },
        },
        fields: ["id"],
      },
    }).catch(() => []);
    return items.length > 0;
  };

  const updateBoard = async (
    boardId: MaybeRef<string>,
    board: DeepPartial<BoardItem>
  ) => {
    return await updateItem({
      collection: "boards",
      id: unref(boardId),
      item: unref(board),
    });
  };

  const deleteBoard = async (boardId: MaybeRef<string>) => {
    return await deleteItems({
      collection: "boards",
      items: [unref(boardId)],
    });
  };

  return {
    getBoard,
    getBoards,
    getBoardImage,
    createBoard,
    hasBoardImage,
    addImageToBoard,
    removeImageFromBoard,
    isImageOnAnyUserBoard,
    updateBoard,
    deleteBoard,
  };
};
